<template>
  <div>
    <notifications group="notification" position="bottom right" />
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <div class="card card-custom card-sticky">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Add New Bundle</h3>
            </div>
            <CardToolbar
              @submitEvent="onSubmit"
              back-text="Back to list"
              back-url="/discounts-bundles/list"
              type="Add"
              button-text="Bundle"
            />
          </div>
          <div class="card-body">
            <form>
              <div class="row form-group">
                <div class="col-md-12">
                  <label class="font-weight-bolder" for="bundle_name"
                    >Name*</label
                  >
                  <input
                    v-model="bundle.name"
                    id="bundle_name"
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="Enter bundle name"
                  />
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-12">
                  <label class="font-weight-bolder" for="bundle_description"
                    >Description</label
                  >
                  <input
                    v-model="bundle.description"
                    id="bundle_description"
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="Enter bundle description"
                  />
                </div>
              </div>
              <div
                v-for="(discount, key) in bundle.discounts"
                :key="key"
                class="row"
                :class="{ 'mb-0': key + 1 === bundle.discounts.length }"
              >
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-6">
                      <div
                        class="input-group"
                        @click.prevent="toggleProductsCategoriesModal(key)"
                      >
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="la la-chain"></i>
                          </span>
                        </div>
                        <input
                          class="form-control"
                          :class="{
                            'font-weight-bolder': !discount.root
                          }"
                          :value="discount.title"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="la la-percentage"></i>
                          </span>
                        </div>
                        <input
                          v-model.number="discount.discount"
                          class="form-control"
                          placeholder="Enter category discount"
                        />
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-group">
                        <div>
                          <button
                            @click.prevent="removeDiscount(key)"
                            class="btn font-weight-bolder btn-light-danger"
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <button
                    @click.prevent="addDiscount"
                    class="btn btn-primary btn-block"
                  >
                    Add Discount
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-modal
          id="products_categories_modal"
          centered
          hide-header
          hide-footer
          size="lg"
        >
          <div
            class="scroll scroll-pull"
            data-scroll="true"
            data-wheel-propagation="true"
          >
            <div class="table-responsive">
              <table class="table table-borderless mb-0">
                <tbody>
                  <tr
                    v-for="(category, key) in filteredCategories"
                    :key="category.id"
                    @click="selectProductCategory(category)"
                  >
                    <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
                      <span v-if="category.root.title">-- </span>
                      <span v-else></span>
                      <span
                        class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                        >{{ category.title }}</span
                      >
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span
                        v-if="category.root.title"
                        class="label label-lg label-light-primary label-inline"
                        >{{ category.root.title }}</span
                      >
                      <span
                        v-else
                        class="label label-lg label-light-danger label-inline"
                        >Root</span
                      >
                    </td>
                    <td class="pr-6 text-right">
                      <button class="btn btn-sm btn-light-primary">
                        Select
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <button
              @click="selectAllProductCategories(filteredCategories)"
              class="btn btn-light-primary btn-block"
            >
              Select All
            </button>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import CardToolbar from '@/view/content/components/card-toolbar/CardToolbar'
import ApiService from '@/core/services/api.service'

export default {
  name: 'NewDiscountsBundles',
  components: {
    CardToolbar
  },
  data() {
    return {
      bundle: {
        name: null,
        description: null,
        discounts: []
      },
      categories: [],
      rootCategories: [],
      filteredCategories: [],
      selectedDiscountKey: null,
      selectedCategory: null
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'All Discounts Bundles', route: '/companies/list' }
    ])

    ApiService.get(
      `/product_categories`,
      `?order[createdAt]=desc&order[position]=asc&itemsPerPage=90`
    ).then(({ data }) => {
      this.totalItems = data['hydra:totalItems']
      this.categories = [...data['hydra:member']]

      this.parentCategories = this.categories.filter(
        category => category.parent === undefined
      )
      this.childrenCategories = this.categories.filter(
        category => category.parent !== undefined
      )

      this.parentCategories.forEach(parentCategory => {
        this.filteredCategories.push(parentCategory)
        this.childrenCategories.forEach(childrenCategory => {
          if (childrenCategory.root['@id'] === parentCategory.root) {
            this.filteredCategories.push(childrenCategory)
          }
        })
      })
    })
  },
  methods: {
    addDiscount() {
      this.bundle.discounts.push({
        id: '',
        root: '',
        title: '',
        slug: '',
        discount: 0
      })
    },
    removeDiscount(key) {
      const confirmation = confirm('Do you want to delete this discount rule?')
      if (confirmation === false) return

      this.bundle.discounts.splice(key, 1)
    },
    toggleProductsCategoriesModal(key) {
      this.selectedDiscountKey = key

      this.$root.$emit('bv::show::modal', 'products_categories_modal')
    },
    selectProductCategory(productCategory) {
      this.selectedCategory = productCategory
      this.bundle.discounts[this.selectedDiscountKey].id = productCategory.id
      this.bundle.discounts[this.selectedDiscountKey].title =
        productCategory.title
      this.bundle.discounts[this.selectedDiscountKey].slug =
        productCategory.slug

      this.$root.$emit('bv::hide::modal', 'products_categories_modal')
    },
    selectAllProductCategories(categories) {
      this.bundle.discounts = []
      categories.forEach(category => {
        this.bundle.discounts.push({
          id: category.id,
          root: category.root.title,
          title: category.title,
          slug: category.slug,
          discount: 0
        })
      })

      this.$root.$emit('bv::hide::modal', 'products_categories_modal')
    },
    onSubmit() {
      ApiService.post('discounts_bundles', this.bundle)
        .then(({ status }) => {
          if (status === 201) {
            this.$notify({
              group: 'notification',
              type: 'success',
              title: 'Discounts bundle successful add in database'
            })
            setTimeout(() => {
              this.$router.push({ name: 'discounts-bundles-list' })
            }, 500)
          }
        })
        .catch(() => {
          this.$notify({
            group: 'notification',
            type: 'error',
            title: 'Discounts bundle submit error'
          })
        })
    }
  }
}
</script>
